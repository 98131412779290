@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

.postContainer {
  
  height: 430px;
  background-color: #ffff;
  margin: 5px;
}

.card {
  width: 95%;
  height: 100%;
  border-radius: 5%;
  overflow: hidden;
  border: 0.1px solid rgb(217, 217, 217);
}

.card__header {
  width: 90%;
  height: 180px;
  border-radius: 10%;
  padding: 8px 8px 0 8px;
  overflow: hidden;
}

.card__body {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 10px;
}

.tag {
  background-color: rgba(181, 181, 238, 0.2);
  color: rgb(21, 38, 188);
  padding: 5px 10px;
  font-size: 10px;
  font-weight: 500;
  border-radius: 5px;
  /* border: 0.01px solid blue; */
  font-family: "Raleway", sans-serif;
  display: flex;
  align-items: center;
}

.card__image {
  width: 90%;
  height: 95%;
  object-fit: cover;
  border-radius: 5%;
}

.postTitle {
  display: flex;
  align-items: center;
  text-align: start;
  padding: 2px;
  width: 100%;
  /* height: 65px; */
  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
}

.postTitle h5 {
  /* font-size: large;
  font-family: "Raleway", sans-serif; */
  font-weight: bold;
  /* color: red; */
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
}

.card__footer {
  position: relative;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px 10px 10px;
}

.user {
  width: 100%;
  display: none;
  align-items: center;
  gap: 9px;
}

.user__info h6 {
  margin-bottom: -7px;
  font-size: 15px;
  font-family: "Raleway", sans-serif;
}

small {
  font-size: 10px;
}

.user__image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.link {
  color: black;
  text-decoration: none;
}

.link:hover {
  text-decoration: none;
  color: black;
}

@media (max-width: 530px) {
  .postContainer {
    margin: auto;

  }
}
