/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

select:focus
image:focus 
label:focus
input:focus,
textarea:focus,
select:focus,
button:focus {
  outline: none; /* Removes the default focus border */
  border-color: transparent; /* Makes the border color transparent */
  box-shadow: none; /* Removes any box-shadow */
}

@tailwind base;
@tailwind components;
@tailwind utilities;

