.calc .title-container {
  display: flex;
  margin: 10px;
}

.calc .title {
  font-size: 1.5em;
  margin: 0px;
}

.calc .title-image {
  opacity: 70%;
  height: 40px;
  margin-left: auto;
}

.calc .tab-content {
  padding-top: 0%;
  background-color: #fff;
  border-radius: 15px;
  border-start-start-radius: 0;
  border-start-end-radius: 0;
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.7);
  max-width: 100%;
}

.calc h2 {
  font-family: "Inter", sans-serif;
  color: #2196ba;
  text-align: left;
  margin-bottom: 20px;
}

.calc tr {
  margin-bottom: 20px;
  border: px solid #ddd;
  border-radius: 010px;
  overflow: hidden;
  background-color: #070707;
  width: 100%;
}

.calc table {
  width: 100%;
  font-family: "Inter", sans-serif;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 10px;
  overflow: hidden;
  margin: 10px auto;
}

.calc th,
.calc td {
  padding: 10px;
  font-family: "Inter", sans-serif;
  text-align: center;
  border: 1px solid #ddd;
}

.calc th {
  background-color: #2196ba;
  color: white;
  font-weight: 600;
}

.calc td {
  background-color: #fff;
}

.calc th:nth-child(1),
.calc td:nth-child(1) {
  width: 25%;
}

.calc th:nth-child(2),
.calc td:nth-child(2) {
  width: 20%;
}

.calc th:nth-child(3),
.calc td:nth-child(3) {
  width: 20%;
}

.calc th:nth-child(4),
.calc td:nth-child(4) {
  width: 25%;
}

.calc th:nth-child(5),
.calc td:nth-child(5) {
  width: 20%;
}

.calc td input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  box-sizing: border-box;
}

.calc td input:hover {
  border-color: #bbb;
}

.calc td input:focus {
  border-color: rgb(38, 39, 40);
  border-width: 0.1pc;
  outline: none;
  box-shadow: none;
}

.calc .total-label {
  font-weight: bold;
  background-color: #f9f9f9;
}

.calc button {
  padding: 10px 20px;
  background-color: #2196ba;
  color: white;
  border: none;
  border-radius: 7px;
  font-weight: bold;
  font-family: "Inter", sans-serif;
  cursor: pointer;
  margin-top: 20px;
  font-size: 16px;
  transition: background-color 0.3s;
}

.calc button:hover {
  background-color: #1976a3;
}

.calc .add-sample-button {
  margin-top: 10px;
  font-family: "Inter", sans-serif;
  font-size: 100%;
}

.calc .content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.calc .table-container {
  display: flex;
  justify-content: center;
  overflow-x: auto;
}

.calc .calculate-button {
  margin-top: 20px;
  margin-left: 20px;
  font-size: 100%;
}

.calc .remove-button {
  background-color: #ff4d4d;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  border: 1px solid #ff4d4d;
  color: white;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s ease, color 0.3s ease;
  box-sizing: border-box;
}

.calc .remove-button:hover {
  background-color: transparent;
  color: #ff4d4d;
  border: 1px solid #ff4d4d;
}

.calc .save-image-button {
  background-color: #3f453f;
  color: #fff;
  border: 1px solid #3f453f;
  margin-top: 20px;
  margin-left: 20px;
  text-align: center;
  display: inline-block;
  font-size: 100%;
  cursor: pointer;
}

.calc .save-image-button:hover {
  background-color: transparent;
  color: #3f453f;
  border: 1px solid #3f453f;
}

@media (max-width: 600px) {
  .calc table {
    display: block;
    width: 150%;
    overflow-x: auto;
    border: none;
    margin: 10px auto;
  }

  .calc thead {
    display: none;
  }

  .calc th:nth-child(1),
  .calc td:nth-child(1) {
    width: 92%;
  }

  .calc th:nth-child(2),
  .calc td:nth-child(2) {
    width: 92%;
  }

  .calc th:nth-child(3),
  .calc td:nth-child(3) {
    width: 92%;
  }

  .calc th:nth-child(4),
  .calc td:nth-child(4) {
    width: 92%;
  }

  .calc th:nth-child(5),
  .calc td:nth-child(5) {
    width: 70%;
  }

  .calc tr {
    display: block;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    background-color: #fff;
    width: 100%;
  }

  .calc td {
    display: block;
    text-align: left;
    padding: 10px;
    border: none;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
    width: 100%;
  }

  .calc td:last-child {
    border-bottom: none;
  }

  .calc td:nth-child(1) {
    display: block;
    text-align: center;
    padding: 10px;
    /* border: none; */
    font-weight: bold;

    border-bottom: 1px solid #ddd;
    width: 100%;
  }

  .calc td:nth-child(2) {
    display: block;
    /* text-align: center; */
    padding: 10px;
    /* border: none; */
    font-weight: bold;

    border-bottom: 1px solid #ddd;
    width: 100%;
  }

  .calc td:nth-child(4) {
    display: block;
    /* text-align: center; */
    padding: 10px;
    /* border: none; */
    font-weight: bold;

    border-bottom: 1px solid #ddd;
    width: 100%;
  }

  .calc td:nth-child(3) {
    display: block;
    text-align: left;
    padding: 10px;
    /* border: 15px solid #333; */
    font-weight: normal;
    border-bottom: 1px solid #ddd;
    width: 100%;
  }

  .calc td::before {
    content: attr(data-label);
    font-weight: bold;
    display: block;
    break-inside: auto;
    margin-bottom: 5px;
    color: #2196ba;
  }

  .calc td input,
  .calc button {
    padding: 12px;
    font-size: 16px;
    width: 100%;
    outline: none;
    box-shadow: none;
  }

  .calc .add-sample-button {
    width: 100%;
    font-family: "Inter", sans-serif;
    margin-top: 10px;
    font-size: 100%;
  }

  .calc .total-label {
    text-align: left;
  }

  .calc .calculate-button {
    margin-left: 0;
    padding: 12px 0;
    font-family: "Inter", sans-serif;
    font-size: 100%;
  }

  .calc .save-image-button {
    background-color: #3f453f;
    color: #fff;
    border: 1px solid #3f453f;
    margin-left: 0;
    padding: 12px 0;
    text-align: center;
    display: inline-block;
    font-size: 100%;
    cursor: pointer;
  }

  .calc .save-image-button:hover {
    background-color: transparent;
    color: #3f453f;
    border: 1px solid #3f453f;
  }

  .calc .remove-button {
    background-color: #ff4d4d;
    border: 1px solid #ff4d4d;
    color: white;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.3s ease, color 0.3s ease;
    box-sizing: border-box;
  }

  .calc .delete-image-in-table {
    text-align: center;
  }

  .calc .remove-button:hover {
    background-color: transparent;
    color: #ff4d4d;
    border: 1px solid #ff4d4d;
  }

  .calc h2 {
    font-family: "Inter", sans-serif;
    color: #2196ba;
    text-align: center;
    padding-bottom: 10px;
    margin-top: 20px;
  }

  .calc .table-container table {
    display: flex;
    justify-content: center;
    overflow-x: auto;
  }

  .calc .title-image {
    display: none;
  }
}
