.share2con {
  display: flex;
  background-color: #1896ba;
  width: 100%;
  height: 100%;
  /* margin: 10px 10px; */
  margin-top: 10px;
  border-radius: 10px;
  justify-content: center;
}

.Jack {
  width: 97%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 70px;
}

.share2 {
  color: white;
  text-transform: capitalize;
  font-size: 14px;
  font-family: sans-serif;
}

.ic {
  margin-top: 4px;
  padding-right: 20px;
  color: white;
  font-size: 25px;
  align-content: center;
}

@media (max-width: 720px) {
  .ic {
    font-size: 20px;
    padding-right: 10px;
  }
}

@media (max-width: 520px) {
  .share2con {
    width: 100%;
    height: auto;
  }
  .share2 {
    height: 50px;
    font-size: 1.5vh;
    font-weight: 600;
  }
  .share2Icons {
    height: 55px;
  }

  .ic {
    padding-right: 10px;
    font-size: 28px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .Jack {
    display: flex;
    flex-direction: column;
  }
}
