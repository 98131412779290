

.Dropdown{
    margin: 5px;
    padding-top: 10px;
    row-gap: 30px;
    flex-direction: column;
    background-color: #fff;
    

  }