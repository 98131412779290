* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* background-color: rgb(255, 255, 255); */
  
}
/* src/App.css */
*{
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.light-mode {
  color: black;
  
}

.dark-mode {
  color: white;
}

select:focus
image:focus 
label:focus
input:focus,
textarea:focus,
select:focus,
button:focus {
  outline: none; /* Removes the default focus border */
  border-color: transparent; /* Makes the border color transparent */
  box-shadow: none; /* Removes any box-shadow */
}
