.calc .calculator-container {
  padding-top: 8%;
  padding-bottom: 4%;
}

.calc .calculator-content {
  background-color: transparent;
  margin: 1%;
  border-radius: 8px;
}
.calc-main-page {
  padding-bottom: 6.4%;
}

.calc .title-background-image-container {
  position: relative;
  overflow: hidden;
  box-shadow: 0 -7px 10px rgba(0, 0, 0, 0.3), 
  -8px 0 10px rgba(0, 0, 0, 0.2), 
  8px 0 10px rgba(0, 0, 0, 0.2),
  0 7px 10px rgba(0, 0, 0, 0);
border-radius: 25px;
  border-end-start-radius: 0;
  border-end-end-radius: 0;
  height: 130px;
  padding: 0%;
}

.calc .title-background-image {
  max-height: 100%;
  width: 100%;
  object-fit: cover;
}

.calc .container-without-image {
  padding: 20px;
  padding-top: 1%;
}

.calc .above-image-text {
  color: black;
  display: block;
  text-align: justify;
  padding: 10px;
  padding-bottom: 0px;
  padding-top: 0%;
  width: 100%;
}

.calc .above-image-text-title {
  color: #2196ba;
  display: block;
  text-align: left;
  font-weight: bold;
  font-size: 140%;
  padding: 10px;
  padding-bottom: 0px;
  width: 100%;
}

.calc .app-bar {
  position: fixed;
  width: 100%;
  padding-top: 4%;
  background-color: white;
  z-index: 1000;
  text-align: center;
  top: 0;
  padding-left: 15.7%;
  box-shadow: 0px 2px 8px rgba(102, 102, 102, 0.3);
}

.calc .tabs-root {
  background-color: white;
  color: #1976a3;
  text-decoration-color: #2196ba;
}

.calc .tab-selected {
  color: #2196ba;
  text-decoration-color: #2196ba;
  font-family: "Inter", sans-serif;
  font-weight: bold;
}

.calc .tabs-indicator {
  background-color: #2196ba;
  font-family: "Inter", sans-serif;
}

.calc .home-title {
  font-size: 250%;
  margin: 30px;
}

.calc-main-page {
  padding: 4.5%;
}

.calc-main-page .home-title-main-page {
  text-align: center;
  font-weight: bold;
  color: #2196ba;
  font-size: 250%;
  padding: 2.5%;
}

.calc .image-container {
  display: flex;
  margin: auto;
  justify-content: center;
}

.calc .app-image {
  max-width: 25%;
  opacity: 50%;
  height: auto;
  margin: 0;
  margin-top: 30px;
  margin-bottom: 40px;
}

.calc .container {
  padding: 30px;
  max-width: 100%;
  margin: 0;
}

.calc .home-container {
  align-items: center;
  margin-top: 50px;
}

.calc .grid-container {
  justify-content: center;
}

.grid-item {
  position: relative;
  overflow: visible;
}

.grid-item .text {
  position: relative;
  color: #2196ba;
  transition: color 0.3s ease;
}

.grid-item img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.grid-item .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 200;
}

.grid-item:hover .text {
  color: white;
  position: relative;
  opacity: 10000;
  z-index: 999999;
}
.grid-item:hover img {
  opacity: 0.9;
  z-index: 10;
}

.grid-item:hover .overlay {
  opacity: 0.4;
}

@media (max-width: 600px) {
  .calc .app-bar {
    position: sticky;
    width: 100%;
    padding-top: 18%;
    background-color: white;
    z-index: 1;
    text-align: center;
    padding-left: 0%;
  }

  .calc .tabs-root {
    flex-direction: column;
    overflow-x: auto;
    color: #1976a3;
    text-decoration-color: #2196ba;
    background-color: white;
  }

  .calc .tab-selected {
    color: #2196ba;
    text-decoration-color: #2196ba;
    font-family: "Inter", sans-serif;
    font-weight: bold;
  }

  .calc .tabs-indicator {
    background-color: #2196ba;
    font-family: "Inter", sans-serif;
  }

  .calc .image-container {
    display: flex;
    justify-content: center;
    margin: 0;
  }

  .calc .app-image {
    max-width: 70%;
    opacity: 50%;
    height: auto;
    margin: 0;
    margin-top: 30px;
    margin-bottom: 60px;
  }

  .calc .container {
    padding: 15px;
    max-width: 100%;
    margin: 0;
  }

  .calc .home-title {
    font-size: 180%;
    margin-top: 20px;
  }

  .calc .home-container {
    max-height: 100%;
    max-width: 100%;
    align-items: center;
    margin-top: 20px;
  }

  .calc .grid-container {
    justify-content: center;
  }

  .calc .grid-button {
    color: grey;
    font-size: 3.2rem;
    border: 2px solid #2196ba;
    text-decoration-color: #2196ba;
    cursor: pointer;
    box-sizing: border-box;
    transition: transform 0.2s ease-in-out, background-color 0.3s, border 0.3s;
  }

  .calc .grid-button:hover {
    background-color: #fff;
    border-radius: 25px;
    border: 2px solid #1976a3;
    transform: scale(1.005);
  }

  .calc .calculator-container {
    padding: 1%;
    padding-top: 5%;
    padding-bottom: 5%;
    border-radius: 15px;
    background-color: transparent;
  }

  .calc .calculator-content {
    background-color: transparent;
    margin: 1.5%;
    border-radius: 15px;
    box-shadow: 0px 6px 8px rgba(102, 102, 102, 0.5),
      0px -2px 10px rgba(102, 102, 102, 0.5);
  }

  .calc .above-image-text {
    padding-left: 3%;
    padding-right: 4%;
    padding-bottom: 0%;
  }

  .calc .above-image-text-title {
    color: #2196ba;
    display: block;
    text-align: left;
    font-weight: bold;
    font-size: 140%;
    padding: 3%;
    padding-top: 5%;
    padding-bottom: 0%;
  }

  .calc .title-background-image-container {
    border-radius: 0px;
    height: 115px;
    padding: 0%;
    box-shadow: none;
  }

  .calc .title-background-image {
    border-radius: 0%;
  }

  .calc-main-page {
    padding-bottom: 12%;
    padding-left: 2%;
    padding-right: 2%;
  }

  .calc-main-page .home-title-main-page {
    padding-top: 20%;
    padding-bottom: 7%;
    font-size: 200%;
  }
}
