.calc .water-consumption-body {
  width: auto;
  max-width: 100%;
  min-width: 55%;
  border-radius: 10px;
  font-family: "Inter", sans-serif;
  background-color: transparent;
}

.calc h1 {
  text-align: center;
  color: #2196ba;
  margin-bottom: 20px;
}

.calc .demand-input {
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
}

.calc .demand-input h3 {
  color: #333;
  margin-bottom: 10px;
  padding-left: 1px;
}

.calc .demand-input label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #2196ba;
  outline: none;
  box-shadow: none;
}

.calc .h2-water-consumption {
  font-family: "Inter", sans-serif;
  color: grey;
  text-align: left;
  margin: 0px;
  margin-bottom: 15px;
  margin-top: 5px;
}

.calc .demands-result {
  font-family: "Inter", sans-serif;
  color: black;
  text-align: left;
  font-size: 16px;
  margin: 0px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.calc .final-result-water-consuptions {
  font-family: "Inter", sans-serif;
  color: black;
  text-align: left;
  font-size: 16px;
  margin: 0px;
  margin-bottom: 5px;
  margin-top: 10px;
}

.calc .demand-result-title {
  font-family: "Inter", sans-serif;
  color: grey;
  text-align: left;
  font-size: 13px;
  font-weight: normal;
  margin: 0px;
  margin-bottom: 10px;
  margin-top: 5px;
}

.calc .demand-title {
  font-family: "Inter", sans-serif;
  color: #525252;
  text-align: left;
  margin: 0px;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 15px;
  margin-top: 5px;
}

.calc .demand-input input {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  color: #333;
  outline: none;
  box-shadow: none;
}

.calc .demand-input select {
  width: 100%;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  color: #333;
  outline: none;
  box-shadow: none;
}

.calc .demand-input select:focus {
  border-color: rgb(204, 210, 215);
  border-width: 0.05pc;
  outline: none;
}

.calc .demand-input input:focus {
  border-color: rgb(38, 39, 40);
  border-width: 0.1pc;
  outline: none;
}

.calc .add-demand-button-water-consumption {
  display: inline-block;
  padding: 12px 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #2196ba;
  color: white;
  border: none;
  border-radius: 7px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.calc .calculate-button-water-consumption {
  display: inline-block;
  padding: 12px 20px;
  align-self: center;
  margin-top: 10px;
  margin-left: 20px;
  background-color: #2196ba;
  color: white;
  border: none;
  border-radius: 7px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.calc .remove-button-water-consumption {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #ff4d4d;
  color: white;
  border: 1px solid #ff4d4d;
  border-radius: 7px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.calc .calculate-button-water-consumption:hover,
.calc .add-demand-button-water-consumption :hover {
  background-color: #1b7c9e;
}

.calc .remove-button-water-consumption:hover {
  background-color: transparent;
  color: #ff4d4d;
  border: 1px solid #ff4d4d;
}

.calc .output-section {
  margin-top: 30px;
  padding: 20px;
  background-color: #eef6f9;
  border-radius: 8px;
  text-align: left;
}

.calc .output-section h2 {
  color: #333;
  margin-bottom: 10px;
}

.calc .divider-water-consumption {
  background-color: #333333;
  margin-top: 1.5%;
  margin-bottom: 2.8%;
  border: 1.8% solid grey;
}

@media (max-width: 600px) {
  .calc .demand-input {
    padding: 15px;
  }

  .calc .calculate-button-water-consumption {
    display: inline-block;
    padding: 12px 20px;
    margin-top: 10px;
    margin-left: 0px;
    background-color: #2196ba;
    color: white;
    border: none;
    border-radius: 7px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }

  .calc .add-demand-button-water-consumption {
    width: 100%;
    margin-top: 16px;
  }

  .calc .remove-button-water-consumption {
    width: 70%;
    margin-top: 16px;
  }

  .calc .output-section h2 {
    font-size: 18px;
  }
  .calc .divider-water-consumption {
    border: 0.35px solid grey;
    margin-top: 5%;
    margin-bottom: 10%;
  }
}
