.calc .aqi-calculator {
  width: 67%;
  margin: auto;
  border-radius: 10px;
  font-family: "Inter", sans-serif;
}

.calc h1 {
  text-align: center;
  color: #2196ba;
  margin-left: 1px;
  margin-bottom: 20px;
}

.calc .aqi-calculator-text {
  color: #2196ba;
  padding-top: 5px;
  align-self: center;
  font-size: 125%;
  font-weight: bold;
  text-align: center;
  align-content: center;
}

.calc .aqi-calculator-input {
  margin-bottom: 10px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
}

.calc .aqi-calculator-input h3 {
  color: #333;
  margin-bottom: 10px;
}

.calc .aqi-calculator-input label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #2196ba;
}

.calc .aqi-calculator-input input {
  width: 100%;
  padding: 8px;
  margin-bottom: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  color: #333;
  outline: none;
  box-shadow: none;
}

.calc .aqi-calculator-input select {
  width: 100%;
  padding: 8px 35px 8px 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  color: #333;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23333' d='M12 15.5l-5-5h10z'/%3E%3C/svg%3E")
    no-repeat right 15px center;
  background-color: #fff;
  background-size: 12px;
  appearance: none;
  outline: none;
  box-shadow: none;
}

.calc .aqi-calculator-input select:focus {
  border-color: rgb(204, 210, 215);
  border-width: 0.05pc;
  outline: none;
}

.calc .aqi-calculator-input input:focus {
  border-color: rgb(38, 39, 40);
  border-width: 0.1pc;
  outline: none;
}

.calc .input-container {
  margin-bottom: auto;
}

.calc .input-with-unit {
  position: relative;
}

.calc .input-with-unit input {
  width: 100%;
  padding: 8px;
  padding-right: 35%;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  color: #333;
}

.calc .input-with-unit .unit {
  background-color: #1b7c9e;
  border-end-end-radius: 3px;
  border-start-end-radius: 3px;
  position: absolute;
  text-align: center;
  top: 45%;
  padding: 5%;
  right: 0.5%;
  min-width: 35%;
  transform: translateY(-50%);
  font-size: 16px;
  color: #fff;
  pointer-events: none;
}

.calc .h2-aqi-calculator {
  font-family: "Inter", sans-serif;
  color: grey;
  text-align: left;
  margin: 0px;
  margin-bottom: 15px;
  margin-top: 5px;
}

.calc .calculate-button-aqi-calculator {
  display: inline-block;
  padding: 11px 20px;
  margin-top: 10px;
  background-color: #2196ba;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.calc .calculate-button-aqi-calculator:hover {
  background-color: #1b7c9e;
}

.calc .pollutants-grid {
  display: grid;
  gap: 10px;
}

.calc .table-container-aqi-calculator {
  display: grid;
  gap: 5%;
  row-gap: 7%;
  column-gap: 2%;
  margin-left: 0%;
  margin-right: 0%;
}

.calc .grid-item-aqi-calculator {
  padding: 0px;
  text-align: center;
}

.calc .pollutant-name {
  font-weight: 500;
}

.calc .pollutant-value {
  color: #333;
  background-color: #fff;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 5%;
  padding-bottom: 5%;
}

.calc .pollutant-value-last {
  color: #333;
  background-color: #fff;
  border-end-start-radius: 10px;
  border-end-end-radius: 10px;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 5%;
  padding-bottom: 5%;
}

.calc .border-pollutant-name {
  color: #333;
  border-end-start-radius: 0px;
  border-end-end-radius: 0px;
  border-start-end-radius: 10px;
  border-start-start-radius: 10px;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 5%;
  padding-bottom: 5%;
  border: 1px solid #b0acac;
}

.calc .border-pollutant-value {
  color: #333;
  background-color: #fff;
  border-end-start-radius: 10px;
  border-end-end-radius: 10px;
  border-start-end-radius: 0px;
  border-start-start-radius: 0px;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 5%;
  padding-bottom: 5%;
  border: 1px solid #b0acac;
  border-top-width: 0px;
}

.calc .border-pollutant-value.missing {
  color: #333;
  background-color: #b0b0b0;
  border-end-start-radius: 10px;
  border-end-end-radius: 10px;
  border-start-end-radius: 0px;
  border-start-start-radius: 0px;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 5%;
  padding-bottom: 5%;
  border: 1px solid #b0acac;
  border-top-width: 0px;
}

.calc .border-pollutant-value-last {
  color: #333;
  border-end-start-radius: 10px;
  border-end-end-radius: 10px;
  background-color: #b0acac;
  border-start-end-radius: 0px;
  border-start-start-radius: 0px;
  border: 1px solid #b0acac;
  border-top: 1px solid #fff;
}

.calc .border-pollutant-value-middle-aqi {
  color: #333;
  background-color: #b0acac;
  border: 1px solid #b0acac;
  border-top-width: 0px;
  border-bottom-width: 1px;
}

.calc .pollutant-value.missing {
  background-color: #b0acac;
  border-end-start-radius: 10px;
  border-end-end-radius: 10px;
  border-start-end-radius: 0px;
  border-start-start-radius: 0px;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 5%;
  padding-bottom: 5%;
  color: #333;
}

.calc .pollutant-value-last.missing {
  background-color: #b0acac;
  border-end-start-radius: 10px;
  border-end-end-radius: 10px;
  border-start-end-radius: 0px;
  border-start-start-radius: 0px;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 5%;
  padding-bottom: 5%;
  color: #333;
}

.calc .output-section-aqi-calculator {
  margin-top: 50px;
  font-size: medium;
}

.calc .output-section-aqi-calculator table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.calc .output-section-aqi-calculator td {
  min-width: 170px;
  padding-left: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 20px;
  text-align: left;
}

.calc .output-section-aqi-calculator th {
  min-width: 170px;
  background-color: #f7f7f7;
  color: black;
  padding-left: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 20px;
  text-align: center;
}

.calc .output-section-aqi-calculator strong {
  font-weight: bold;
}

@media (min-width: 601px) {
  .calc .pollutants-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .calc .table-container-aqi-calculator {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 600px) {
  .calc .pollutants-grid {
    grid-template-columns: 1fr 1fr;
  }

  .calc .table-container-aqi-calculator {
    grid-template-columns: repeat(2, 1fr);
    gap: 5%;
    max-width: 5000px;
  }

  .calc .aqi-calculator {
    width: auto;
    max-width: 100%;
    min-width: 100%;
  }

  .calc .aqi-calculator-body {
    padding: 15px;
  }

  .calc .aqi-calculator-input {
    padding: 15px;
  }

  .calc .calculate-button-aqi-calculator {
    display: inline-block;
    padding: 12px 20px;
    margin-top: 10px;
    margin-left: 0px;
    background-color: #2196ba;
    color: white;
    border: none;
    border-radius: 7px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }

  .calc .output-section-aqi-calculator {
    margin-top: 100px;
    margin-left: 0%;
    margin-right: 0%;
    background-color: transparent;
    font-size: medium;
  }

  .calc .output-section-aqi-calculator table {
    width: 200%;
    margin-top: 10px;
  }

  .calc .output-section-aqi-calculator td {
    padding: 20px;
    padding-left: 25px;
    padding-right: 25px;
  }

  .calc .output-section-aqi-calculator strong {
    font-weight: bold;
  }

  .calc .input-with-unit .unit {
    background-color: #1b7c9e;
    border-end-end-radius: 3px;
    border-start-end-radius: 3px;
    position: absolute;
    text-align: center;
    top: 45%;
    padding: 5.5%;
    right: 0.5%;
    min-width: 45%;
    transform: translateY(-50%);
    font-size: 16px;
    color: #fff;
    pointer-events: none;
  }

  .calc .input-with-unit input {
    padding-right: 38%;
  }
}
