.contact-us-container {
  margin: 80px auto;
  max-width: 75%;
}

.box-container-border {
  border: 1px solid grey;
  margin-top: 3%;
  padding: 1.8%;
  border-radius: 18px;
}

.title {
  width: 100%;
}

.description {
  width: 90%;
}

@keyframes pulse {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.7;
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.contact-form {
  width: 100%;
  border-left: 2px solid;
  display: flex;
  justify-content: center;
}

.form {
  width: 300px;
}

.title {
  font-size: 3rem;
  font-weight: bold;
  color: #2196ba;
}

.description {
  font-size: 1.25rem;
  margin-top: 10px;
}

.contact-section {
  display: flex;
  /* margin-top: 75px; */
}

.contact-info {
  width: 35%;
  margin-right: 30px;
}

.contact-info-item {
  margin-bottom: 20px;
}

.contact-label {
  font-size: 1.5rem;
  font-weight: bold;
}

.contact-text {
  font-size: 1.1rem;
}

.contact-email {
  color: #2196ba;
  font-weight: bold;
}

.horizontal-line {
  height: 1px;
  background-color: black;
  margin: 10px 0;
}

#name.input,
#email.input,
#contactno.input,
#msg.textarea {
  border-radius: 7px;
}

.form-group {
  margin-bottom: 20px;
}

.label {
  font-size: 1.25rem;
}

.input,
.textarea {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.textarea {
  resize: vertical;
}

.required {
  color: red;
  margin-left: 5px;
}

.submit-button {
  margin-top: 20px;
}

.loading-text {
  margin-left: 5px;
}

.join-us-section {
  margin: 20px auto;
  max-width: 75%;
  text-align: center;
}

.join-us-section h1 {
  font-size: 3rem;
  font-weight: bold;
  color: #2196ba;
  align-self: center;
}

.Role {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  text-align: center;
}

.Role-member {
  text-align: center;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 22%; /* Adjust flex-basis as needed for spacing */
}

.logo {
  border: 1px solid #2196ba;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px; /* Add margin for spacing */
}

.logo .material-symbols-outlined {
  font-size: 3em;
}

.Role-member h3 {
  font-size: 1.6em;
  margin: 10px 0; /* Add margin for spacing */
}

.Role-member p {
  font-size: 1.2rem;
  margin-top: 10px; /* Add margin for spacing */
}

.Role-member a i {
  font-size: 1.7rem;
  color: #2196ba;
  margin: 0 0.3em;
}

.Role-member a {
  text-decoration: none;
  color: black;
}

.faq-section {
  margin: 50px auto;
  max-width: 75%;
  text-align: left;
}

.wrapper h1 {
  font-size: 2rem;
  font-weight: bold;
  color: #2196ba;
}

.accordion {
  background-color: white;
  color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
  font-size: 1.3rem;
  width: 100%;
  padding: 1.5%;
  border: none;
  outline: none;
  transition: 0.4s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
}

.accordion i {
  text-align: left;
  font-size: 1.6rem;
}

.active,
.accordion:hover {
  background-color: #2196ba;
  color: white;
}

.pannel {
  padding: 1.5%;
  background-color: white;
  overflow: hidden;
  background-color: #52b2d0;
  display: none;
}

.pannel p {
  color: white;
  font-size: 1.1rem;
  line-height: 1.4;
}

.faq {
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin: 10px 0;
}
.faq.active {
  border: none;
}

@media (max-width: 930px) {
  .contact-us-container {
    margin: 80px auto;
    max-width: 85%;
  }

  .title {
    width: 70%;
  }
  .description {
    width: 90%;
  }

  .contact-info {
    width: 50%;
    margin-right: 0px;
  }
  .contact-info-item {
    margin-bottom: 30px;
  }

  .Role-member {
    flex-basis: 45%;
  }
}

@media (max-width: 640px) {
  .contact-us-container {
    margin: 80px auto;
    max-width: 85%;
  }

  .accordion {
    text-align: left;
    font-size: 1rem;
    padding: 5%;
  }

  .pannel {
    padding: 5%;
  }

  .pannel p {
    font-size: 0.95rem;
  }

  .title {
    width: 90%;
    text-align: center;
  }
  .description {
    width: 100%;
    text-align: center;
  }

  .contact-info {
    width: 90%;
    margin-right: 0px;
  }
  .contact-info-item {
    margin-bottom: 30px;
  }

  .Role-member {
    flex-basis: 90%;
  }
}

@media (max-width: 930px) {
  .Role-member {
    flex-basis: 45%;
  }
}

@media (max-width: 640px) {
  .Role-member {
    flex-basis: 90%;
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.7;
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.contact-form {
  margin-left: 5%;
  width: 100%;
  border-left: 1px solid grey;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
}

.form {
  width: 300px;
}

.title {
  font-size: 3rem;
  font-weight: bold;
  color: #2196ba;
}

.description {
  font-size: 1.25rem;
  margin-top: 10px;
}

.contact-section {
  display: flex;
  /* margin-top: 75px; */
}

.contact-info {
  width: 35%;
  margin-right: 30px;
}

.contact-info-item {
  margin-bottom: 20px;
}

.contact-label {
  font-size: 1.5rem;
  font-weight: bold;
}

.contact-text {
  font-size: 1.1rem;
}

.contact-email {
  color: #2196ba;
  font-weight: bold;
}

.horizontal-line {
  height: 1px;
  background-color: black;
  margin: 10px 0;
}

.form {
  width: 75%;
}

#name.input,
#email.input,
#contactno.input,
#msg.textarea {
  border-radius: 7px;
}

.form-group {
  margin-bottom: 20px;
}

.label {
  font-size: 1.25rem;
}

.input,
.textarea {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.textarea {
  resize: vertical;
}

.required {
  color: red;
  margin-left: 5px;
}

.submit-button {
  margin-top: 20px;
}

.loading-text {
  margin-left: 5px;
}

@media (max-width: 930px) {
  .contact-us-container {
    margin: 80px auto;
    max-width: 85%;
  }

  .title {
    width: 70%;
  }
  .description {
    width: 85%;
  }

  .contact-info {
    width: 50%;
    margin-right: 0px;
  }
  .contact-info-item {
    margin-bottom: 30px;
  }
}

@media (max-width: 640px) {
  .contact-us-container {
    margin: 80px auto;
    max-width: 85%;
  }

  .title {
    width: 90%;
  }

  .description {
    width: 100%;
  }

  .box-container-border {
    border: 0px;
    padding-top: 15%;
  }

  .contact-section {
    display: flex;
    flex-direction: column;
    /* margin-top: 50px; */
  }

  .contact-form {
    width: 100%;
    /* border-top: 2px solid; */
    border-left: 0px;
    display: flex;
    /* margin-top: 5px;  */
    padding-top: 10px;
    justify-content: center;
  }

  .form {
    width: 100%;
  }

  .contact-info {
    width: 100%;
    margin-right: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .contact-info-item {
    width: 100%;
  }
}

@media (max-width: 430px) {
  .contact-us-container {
    margin: 80px auto;
    max-width: 85%;
  }

  .title {
    width: 100%;
  }

  .description {
    width: 100%;
  }

  .contact-section {
    display: flex;
    flex-direction: column;
    /* margin-top: 50px; */
  }

  .contact-form {
    width: 100%;
    /* border-top: 2px solid; */
    border-left: 0px;
    display: flex;
    /* margin-top: 5px;  */
    padding-top: 10px;
    justify-content: center;
  }

  .form {
    width: 100%;
  }

  .contact-info {
    width: 100%;
    margin-right: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .contact-info-item {
    width: 100%;
    margin-bottom: 20px;
  }
}