.navbarContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* color: white; */
  width: 100%;
  height: 80px;
  /* border-bottom: 1px solid black; */
  padding: 5px;
  position: fixed;
  top: 0;
  /* background-color: rgba(255, 255, 255); */
  z-index: 999999;
}
.centerItem {
  @apply px-4 py-2 rounded-md relative transition duration-200 ease-in-out;
}

.centerItem::after {
  content: "";
  display: block;
  width: 0;
  height: 2.5px;
  background: #1ec2f4;
  transition: width 0.2s;
  position: absolute;
  bottom: 0;
  left: 0;
}

.centerItem:hover::after {
  width: 100%;
}

.active::after {
  width: 100%;
}

.navbarContainer {
  transition: background-color 0.1s ease;
}
.scrolled {
  background-color: white;
}

/* .navbarContainer:hover {
  background-color: #fcf5f5;
} */
@font-face {
  font-family: myFont;
  src: url(../../../public/fonts/Typo_Round_Bold_Demo.otf);
}

@font-face {
  font-family: myItalicBold;
  src: url(../../../public/fonts/Typo_Round_Italic_Demo.otf);
}

@font-face {
  font-family: myItalic;
  src: url(../../../public/fonts/Typo_Round_Italic_Demo.otf);
}

@font-face {
  font-family: myThin;
  src: url(../../../public/fonts/Typo_Round_Thin_Demo.otf);
}

@font-face {
  font-family: myLightItalic;
  src: url(../../../public/fonts/Typo_Round_Light_Italic_Demo.otf);
}

@font-face {
  font-family: myBold;
  src: url(../../../public/fonts/Typo_Round_Bold_Demo.otf);
}

.logoContainer {
  width: 180px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}

.logoNevbar {
  width: 140px;
  height: auto; /* Maintain aspect ratio */
}

.dropbox {
  position: fixed;
  top: -10px;
  left: 0;
  height: 200%;
  max-width: 65%;
  width: 100%;
  padding-top: 60px;
  padding-left: 10px;
  row-gap: 30px;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.4s ease;
  z-index: 9999999;
}

.searchbarMenu {
  margin-top: -10px;
  display: flex;
  width: 80%;
  margin: auto;
  background: transparent;
  border-radius: 10px;
  border: 1px solid;
  padding: 2px;
  padding-left: 10px;
  align-items: center;
  /* background-color: #1ec2f4; */
}

.searchbarInputmenu {
  margin: auto;
}

.dropdown-item {
  /* margin: auto; */
  display: flex;
  padding-left: 10px;
  margin-block: 25px;
  justify-content: left;
  align-items: left;
  font-size: large;
  color: #333;
  font-weight: bold;
  text-decoration: none;
}

.dropdown-item-join-community {
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 10px;
  margin-right: 35px;
  padding-block: 12px;
  justify-content: center;
  align-content: center;
  font-size: large;
  color: #fff;
  font-weight: bold;
  border-radius: 5px;
  background-color: #2196ba;
  text-decoration-color: #fff;
}

/* .dropdown-item:hover {
  background-color: #2196ba;
  color: #fff;
} */

.dropbox-reading {
  position: absolute;
  z-index: 9999999;
  top: 90px;
  left: 372px;
  background-color: #1ec2f4;
}

.leftNevbar {
  display: flex;
  align-items: center;
  width: 1000px;
  display: flex;
  /* background-color: red; */
  /* align-content: center; */
  /* background: #946969; */
  gap: 30px;
}

.rightNevbar {
  margin-right: 10px;
  display: flex;
  align-items: left;
  justify-content: flex-start;
  gap: 15px;

  /* background-color: #403131; */
}

.userprofile img {
  width: 40px;
  border-radius: 50%;
}

.searchbar {
  background: transparent;
  border-radius: 10px;
  border: 1px solid;
  padding: 15px;
  width: 500px;
  height: 6vh;
  display: flex;
  align-items: center;
}

.menu {
  display: none;
  font-size: 12px;
  /* background-color: #5c3535; */
}

.community {
  display: inline;
  padding: 0%;
}

@media only screen and (max-width: 957px) {
  .read {
    display: none;
  }
  .centerItem {
    display: none;
  }

  .leftNevbar {
    width: 150px;
  }
  .logoNevbar {
    margin-left: 43px;
  }
  .logoContainer {
    margin-left: 30px;
  }
}

@media only screen and (max-width: 570px) {

  .navbarContainer {
    background-color: rgba(255, 255, 255);
  }
  
  /* .navbarContainer {
    flex-direction: column;
    align-items: center;
  } */

  .rightNevbar {
    justify-content: space-between;
    width: 140px;
  }

  .searchbar {
    display: none;
  }

  .logoNevbar {
    width: 110px;
  }
}

@media only screen and (max-width: 1207px) {
  .readings {
    display: none;
  }

  .menu {
    display: block;
  }

  .searchbar {
    display: none;
  }
}

@media only screen and (max-width: 1027px) {
  .readingmenu {
    display: none;
  }
}

.centerItems {
  width: 100%;
  display: flex;
  align-items: center;
  font-family: myBold;
  font-weight: 500;
  gap: 30px;
  margin-top: 0;
  /* background-color: #512f2f; */
}

.searchIcon {
  color: rgb(44, 142, 222) !important;
  padding-right: 5px;
  font-size: 25px;
}

.searchbarInput {
  background: transparent;
  border: none;
  width: 97%;
}

.searchbarInput:focus {
  /* outline: none; */
  border: #fff;
}

/* .links {
  color: black;
  text-decoration: none;
} */

.navbarContainer button {
  border: none;
  cursor: pointer;
  font-family: myBold;
  background-color: transparent;
}

ul {
  list-style: none;
  /* padding: unset;
  margin: unset; */
}

/* li {
  background-color: #fff;
  padding: 14px;
  transition: all linear 0.3s;
} */

li:hover {
  /* background: #1ec2f4; */
  /* color: black; */
  cursor: pointer;
  /* color: #fff; */
}

.dropdown-menu {
  background: #fff;
  box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
  transition: all linear 0.3s;
}

.readingmenu {
  z-index: 99999;
  position: absolute;
  left: 400px;
  font-family: myBold;
}

.searchResults {
  position: absolute;
  top: 60px;
  width: 400px;
}